import { getCookies, setCookies, userSessionCookieKey } from 'libs/common/cookies'
import { getSearchParams } from 'utils/queryString.utils'

import {
  createLegacyUserEventsApi,
  createUserEventsApi,
  LegacyTrackingUserEventsApi,
  TrackingUserEventsApi,
} from 'shop/api/trackingUserEvents.api'
import { computed } from 'mobx'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { flattenObject } from 'shop/utils/transormation.utils'
import { isShopPreview } from 'shop/utils/preview.utils'
import { deepObjectPropNamesToSnakeCase } from 'utils/nameStyle.utils'

const EVENT_REGISTRY = {
  shop_checkout: [
    'page_view',
    'pricing_plan_change',
    'payer_type_change',
    'vat_id_check',
    'gift_mode_change',
    'payment_method_change',
    'coupon_view_change',
    'coupon_input_blur',
    'coupon_success_submit',
    'coupon_error_submit',
    'extend_checkout_summary_click',
    'buy_button_click',
    'buy_button_success',
    'buy_button_error',
    'klarna_selected_checkout',
    'klarna_selected_manage_page',
    'express_button_click',
    'express_button_success',
    'express_button_error',
    'payment_method_accordion_change',
  ] as const,
  shop_thank_you: ['page_view', 'funnel_page_view', 'funnel_accept', 'funnel_reject'] as const,
  shop_manage: [
    'page_view',
    'change_payment_method_radio_button_click',
    'change_payment_method_modal_submitted',
    'change_billing_address',
    'confirm_revoke_cancellation_button_click',
    'confirm_cancellation_button_click',
    'cancel_button_click',
    'revoke_cancellation_button_click',
    'change_payment_method_modal_open',
    'change_billing_address_button_click',
    'pay_total',
    'pay_debts',
    'pay_next_amount',
    'pay_debt',
  ] as const,
  shop_custom_page: ['page_view'] as const,
  shop_product: ['page_view'] as const,
  shop_root: ['page_view'] as const,
} as const

export type PageType = keyof typeof EVENT_REGISTRY

export type EventType<T extends PageType> = (typeof EVENT_REGISTRY)[T][number]

type ShopDetails = {
  sellerId: string
  userSessionId: string
  userAgent: string
  pageUrl: string
  pageReferer: string
  userId?: string
}

export type TrackingUserEvent = {
  pageType: PageType
  eventType: `${PageType}__${EventType<PageType>}`
  eventDetails: ShopDetails
  pageDetails: PageDetails
  eventPayload: {
    experiments: Record<string, { name: string; value: Record<string, unknown> }>
  }
}

type PageDetails =
  | CheckoutPageDetails
  | ProductPageDetails
  | ThankYouPageDetails
  | ManagePageDetails
  | Record<string, never>

type CheckoutPageDetails = {
  product: {
    type: string
    free: boolean
    id: string
    slug: string
  }
  pricingPlans: {
    pricingPlans: Array<{
      type: string
      id: string
      price: number
    }>
    selectedPricingPlanId: string | number
    selectedPricingPlanIndex: number
    selectedPricingPlanType: string
    selectedPricingPlanPrice: number
    pricingPlansCount: number
  }
  summary: {
    totalPrice: number
  }
  payerForm: string
  buyRecovery?: {
    orderId: string | string[]
  }
  upsell: {
    hasAnyUpsells: boolean
    type: string
    isMainProductRequired: boolean
  }
}

type ProductPageDetails = {
  product: {
    id: string
    slug: string
  }
}

type ThankYouPageDetails = {
  payerId: string
  orderId: string
  sellerId: string
}

type ManagePageDetails = {
  payerId: string
  orderId: string
  sellerId: string
}

export class TrackingUserEventsStore {
  storeName = 'TrackingUserEventsStore'
  private api: TrackingUserEventsApi
  private legacyApi: LegacyTrackingUserEventsApi

  constructor(private root: ShopRootStore) {
    this.api = createUserEventsApi(root.apiClient)
    this.legacyApi = createLegacyUserEventsApi(root.apiClient)
  }

  logEvent<T extends PageType>({
    pageType,
    eventType,
    payload = {},
    excludeLogService = false,
    excludeExperimentService = false,
    withExperimentDetails = false,
  }: {
    pageType: T
    eventType: EventType<T>
    payload?: Record<string, any>
    excludeLogService?: boolean
    excludeExperimentService?: boolean
    withExperimentDetails?: boolean
  }) {
    if (!this.shouldLogEvent) {
      return
    }

    if (!excludeLogService) {
      this.dispatchLogService(pageType, eventType, payload, withExperimentDetails)
    }

    if (!excludeExperimentService) {
      this.dispatchExperimentService(pageType, eventType, payload)
    }
  }

  logLegacyEvent = (data: { actionType: string; sellerId: number; productId?: number }) => {
    if (!this.shouldLogEvent) {
      return
    }

    this.legacyApi.track({ ...data, clickerToken: this.clickerToken })
  }

  private dispatchLogService<T extends PageType>(
    pageType: PageType,
    eventType: EventType<T>,
    payload?: Record<string, any>,
    withExperimentDetails = false
  ) {
    this.api.track({
      pageType,
      eventType: this.makeEventType(pageType, eventType),
      eventDetails: this.getShopDetails,
      pageDetails: this.getEventDetailsByPageType(pageType),
      eventPayload: {
        experiments: withExperimentDetails ? this.getAllActiveExperimentsDetails : {},
        ...payload,
      },
    })
  }

  private dispatchExperimentService<T extends PageType>(
    pageType: PageType,
    eventType: EventType<T>,
    payload?: Record<string, any>
  ) {
    this.logExperiment(pageType, this.makeEventType(pageType, eventType), {
      ...payload,
      ...this.getShopDetails,
      ...this.getEventDetailsByPageType(pageType),
    })
  }

  private makeEventType<T extends PageType>(
    pageType: PageType,
    eventType: EventType<T>
  ): `${PageType}__${EventType<T>}` {
    return `${pageType}__${eventType}`
  }

  private logExperiment<T extends PageType>(
    pageType: T,
    eventType: `${T}__${EventType<T>}`,
    payload?: Record<string, unknown>
  ) {
    this.root.experimentsStore.logEvent(eventType, pageType, deepObjectPropNamesToSnakeCase(flattenObject(payload)))
  }

  private get shouldLogEvent() {
    return !isShopPreview(getSearchParams())
  }

  @computed private get getShopDetails(): ShopDetails {
    return {
      sellerId: this.sellerId,
      userId: this.userId,
      userSessionId: this.userSessionId,
      userAgent: this.userAgent,
      pageUrl: this.pageUrl,
      pageReferer: this.pageReferer,
    }
  }

  private getEventDetailsByPageType(pageType: PageType): PageDetails {
    switch (pageType) {
      case 'shop_checkout': {
        return this.checkoutPageDetails
      }
      case 'shop_product': {
        return this.productPageDetails
      }
      case 'shop_thank_you': {
        return this.managePageDetails
      }
      case 'shop_manage': {
        return this.managePageDetails
      }
      default: {
        return {}
      }
    }
  }

  @computed private get sellerId(): string {
    return this.root.sellerStore.sellerId.toString()
  }

  @computed private get payerId(): string {
    return this.root.ordersStore.data?.payer?.id.toString()
  }

  @computed private get userId(): string {
    return this.root.userStore.item?.id?.toString()
  }

  @computed private get product() {
    return {
      id: this.root.productsStore.product?.id?.toString(),
      slug: this.root.productsStore.product?.slug,
      type: this.root.productsStore.product.form,
      free: this.root.paymentStore.product.free,
    }
  }

  @computed private get orderId(): string {
    return this.root.ordersStore.data?.id?.toString()
  }

  @computed private get userSessionId(): string {
    return getCookies(userSessionCookieKey)
  }

  @computed private get checkoutPageDetails(): CheckoutPageDetails {
    return {
      product: this.product,
      pricingPlans: this.pricingPlans,
      summary: {
        totalPrice: this.root.paymentStore.totalPrice(),
      },
      payerForm: this.root.themeStore.ppTemplate.buyerInformation[this.root.paymentStore.payerFromType],
      upsell: {
        hasAnyUpsells: this.root.paymentStore.hasAnyUpsells,
        type: this.root.paymentStore.upsellType,
        isMainProductRequired: this.root.paymentStore.isMainProductRequired,
      },
      ...this.buyRecoveryDetails,
    }
  }

  @computed private get productPageDetails(): ProductPageDetails {
    return {
      product: {
        id: this.product.id,
        slug: this.product.slug,
      },
    }
  }

  @computed private get managePageDetails() {
    return {
      payerId: this.payerId,
      orderId: this.orderId,
      sellerId: this.sellerId,
    }
  }

  @computed private get pricingPlans() {
    const pricingPlans = this.root.paymentStore.getProductPlansToShow()
    const selectedPricingPlanId = this.root.paymentStore.store.product.planId
    const selectedPricingPlanIndex = pricingPlans.findIndex((plan) => plan.id === selectedPricingPlanId)
    const selectedPricingPlan = pricingPlans[selectedPricingPlanIndex]

    return {
      pricingPlans: pricingPlans.map(({ form, id, prefs }) => ({
        type: form,
        id,
        price: prefs.price || prefs.firstAmount || 0,
      })),
      selectedPricingPlanId,
      selectedPricingPlanIndex: selectedPricingPlanIndex + 1,
      selectedPricingPlanType: selectedPricingPlan?.form,
      selectedPricingPlanPrice: selectedPricingPlan?.prefs?.price || selectedPricingPlan?.prefs?.firstAmount || 0,
      pricingPlansCount: pricingPlans.length,
    }
  }

  @computed private get userAgent(): string {
    return window.navigator.userAgent
  }

  @computed private get pageUrl(): string {
    return window.location.href
  }

  @computed private get pageReferer(): string {
    return document.referrer
  }

  private get clickerToken() {
    const token = getCookies('clicker_token') ?? this.generateClickerToken()

    setCookies('clicker_token', token)

    return token
  }

  private get buyRecoveryDetails() {
    const { recovery_order_id } = getSearchParams()

    if (!recovery_order_id) {
      return {}
    }

    return {
      buyRecovery: {
        orderId: recovery_order_id,
      },
    }
  }

  private generateClickerToken() {
    return Math.random().toString(36).slice(2)
  }

  private get getAllActiveExperimentsDetails() {
    return this.root.experimentsStore.allActiveExperiments
  }
}
