import { notify } from 'libs/common/notify'

import { getElopageConfig } from 'utils/elopageConfig.utils'
import { isProduction } from 'utils/env.utils'

import {
  Stripe,
  StripeElements,
  StripeExpressCheckoutElement,
  StripeP24BankElement,
  StripeIdealBankElement,
  StripeElementsOptionsMode,
  PaymentIntentResult,
  SetupIntentResult,
  StripeMessageParams,
  KlarnaFormSettings,
} from 'shop/types/stripe'

import { PAYMENT_FORMS } from 'constants/paymentSettingShared.constants'
import { hasWindow } from './browser.utils'

type HandleStripe = Promise<PaymentIntentResult> | undefined

export const getStripeStyles = (paymentForm = '') => {
  switch (paymentForm) {
    case PAYMENT_FORMS.p24:
    case PAYMENT_FORMS.ideal:
    case PAYMENT_FORMS.klarna:
      return {
        base: {
          backgroundColor: '#fff',
          padding: '15px 13px',
          color: '#4a4a4a',
          lineHeight: '16px',
          fontFamily: 'Montserrat Reg, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '13px',
          '::placeholder': {
            color: '#4a4a4a',
          },
          ':hover': {
            backgroundColor: '#E4F0FA',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }

    case PAYMENT_FORMS.card:
    default:
      return {
        base: {
          color: '#32325d',
          lineHeight: '18px',
          fontFamily: 'Nunito Sans, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }
  }
}

export const getNewStripeStyles = (paymentForm = '') => {
  switch (paymentForm) {
    case PAYMENT_FORMS.p24:
    case PAYMENT_FORMS.ideal:
    case PAYMENT_FORMS.klarna:
      return {
        base: {
          backgroundColor: '#fff',
          padding: '16px',
          fontFamily: 'Inter, sans-serif',
          fontSize: '14px',
          fontWeight: 400,

          ':hover': {
            backgroundColor: '#f3f5f8',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }

    case PAYMENT_FORMS.card:
    default:
      return {
        base: {
          color: '#000004',
          fontFamily: 'Inter, sans-serif',
          fontSize: '14px',
          iconColor: '#485056',

          '::placeholder': {
            color: '#8f9295',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }
  }
}

export const isStripeInjected = () => hasWindow && window.Stripe

export const injectStripeScript = (onLoadHandler = () => {}, injected = () => {}): void => {
  /* Do nothing in case it's already injected */
  if (isStripeInjected()) {
    injected()
    return
  }

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://js.stripe.com/v3/'
  document.body.appendChild(script)

  script.onload = () => {
    onLoadHandler()
  }
}

export const createStripeClient = (stripePubKey = '') => {
  if (hasWindow && (window as any).Stripe) {
    return (window as any).Stripe(stripePubKey, { betas: ['klarna_pm_beta_1'] })
  }
}

export const createStripeElements = (
  stripeClient?: {
    elements: (localeObj: { locale: string }) => object
  },
  params?: StripeElementsOptionsMode | { clientSecret: string } | StripeMessageParams
): object | void => {
  if (stripeClient) {
    return stripeClient.elements({
      locale: I18n.locale,
      ...(params ? params : {}),
    })
  }
}

export const createStripeP24 = (
  stripeElements?: {
    create: (provider: string, style: object) => object
  },
  isNewPaymentMethodUI?: boolean
): object | void => {
  if (stripeElements) {
    return stripeElements.create('p24Bank', {
      style: isNewPaymentMethodUI ? getNewStripeStyles(PAYMENT_FORMS.p24) : getStripeStyles(PAYMENT_FORMS.p24),
    })
  }
}

export const createStripeCard = (
  stripeElements?: {
    create: (card: string, style: object) => object
  },
  isNewPaymentMethodUI?: boolean
): object | void => {
  if (stripeElements) {
    return stripeElements.create('card', {
      style: isNewPaymentMethodUI ? getNewStripeStyles(PAYMENT_FORMS.card) : getStripeStyles(PAYMENT_FORMS.card),
    })
  }
}

export const createStripeKlarna = (
  stripeElements: {
    create: (provider: string, options: KlarnaFormSettings) => object
  },
  options: KlarnaFormSettings
): object | void => {
  if (stripeElements) {
    return stripeElements.create('payment', {
      ...options,
    })
  }
}

export const createStripeIdeal = (
  stripeElements?: {
    create: (provider: string, style: object) => object
  },
  isNewPaymentMethodUI?: boolean
): object | void => {
  if (stripeElements) {
    return stripeElements.create('idealBank', {
      style: isNewPaymentMethodUI ? getNewStripeStyles(PAYMENT_FORMS.ideal) : getStripeStyles(PAYMENT_FORMS.ideal),
    })
  }
}

export const createStripeTwint = (
  stripeElements?: {
    create: (type: string, style: object) => object
  },
  options?
) => {
  if (stripeElements) {
    return stripeElements.create('payment', options)
  }
}

export const createStripeSepa = (
  stripeElements?: {
    create: (card: string, style: object) => object
  },
  options?
) => {
  if (stripeElements) {
    return stripeElements.create('payment', options)
  }
}

export const handleSofortTransaction = (
  stripeClient?: Stripe,
  redirectLink?: string,
  clientSecret?: string,
  payer = {
    countryCode: '',
    billingDetails: {},
  }
): HandleStripe => {
  if (stripeClient && clientSecret) {
    return stripeClient.confirmSofortPayment(clientSecret, {
      payment_method: {
        sofort: {
          country: (payer.countryCode || '').toUpperCase(),
        },
        ...payer.billingDetails,
      },
      return_url: redirectLink,
    })
  }
}

export const handleSepaError = (error) => {
  const messageContainer = document.querySelector('#sepa-error-message')
  messageContainer.textContent = error.message
}

export const handleP24Transaction = (
  stripeClient?: Stripe,
  stripeP24?: StripeP24BankElement,
  redirectLink?: string,
  clientSecret?: string,
  payer = {
    fullName: '',
    email: '',
    tosShowAndAccepted: false,
  }
): HandleStripe => {
  if (stripeClient && clientSecret && stripeP24) {
    return stripeClient.confirmP24Payment(clientSecret, {
      payment_method: {
        p24: stripeP24,
        billing_details: {
          name: payer.fullName,
          email: payer.email,
        },
      },
      payment_method_options: {
        p24: {
          // In order to be able to pass the `tos_shown_and_accepted` parameter, you must
          // ensure that the P24 regulations and information obligation consent
          // text is clearly visible to the customer. See
          // https://stripe.com/docs/payments/p24/accept-a-payment#requirements
          // for directions.
          tos_shown_and_accepted: payer.tosShowAndAccepted,
        },
      },
      return_url: redirectLink,
    })
  }
}

export const handleIdealTransaction = (
  stripeClient?: Stripe,
  idealBank?: StripeIdealBankElement,
  redirectLink?: string,
  clientSecret?: string,
  payer = {
    fullName: '',
    email: '',
  }
): HandleStripe => {
  if (stripeClient && clientSecret && idealBank) {
    return stripeClient.confirmIdealPayment(clientSecret, {
      payment_method: {
        ideal: idealBank,
        billing_details: {
          name: payer.fullName,
          email: payer.email,
        },
      },
      return_url: redirectLink,
    })
  }
}
export const getKlarnaFormSettings = (): KlarnaFormSettings => ({
  fields: {
    billingDetails: 'never',
  },
})

export const handleStripeCardTransaction = (
  stripeClient: {
    confirmCardPayment: (clientSecretString: string, paymentMethodData: object) => Promise<PaymentIntentResult>
    confirmCardSetup: (clientSecretString: string, paymentMethodData: object) => Promise<SetupIntentResult>
  },
  paymentMethodUserData: { id?: string | number; stripeCard?: object },
  clientSecret: string,
  userData?: {
    fullName?: string
    email?: string
    tosShowAndAccepted?: boolean
  },
  usePreauthorization = false
): Promise<PaymentIntentResult> | Promise<SetupIntentResult> => {
  if (stripeClient && clientSecret) {
    const billingDetails: { name?: string; email?: string } = {}
    if (userData?.fullName) {
      billingDetails.name = userData?.fullName
    }
    if (userData?.email) {
      billingDetails.email = userData?.email
    }

    const paymentMethodData = paymentMethodUserData.id || {
      card: paymentMethodUserData.stripeCard,
      billing_details: billingDetails,
    }

    // TODO: check and revise the logic - Dunning team
    if (usePreauthorization) {
      return stripeClient.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodData,
      })
    }

    return stripeClient.confirmCardSetup(clientSecret, {
      payment_method: paymentMethodData,
    })
  }
}

export const handleTransactionResponse = (
  result: PaymentIntentResult | SetupIntentResult,
  handleSuccess: () => void,
  handleError: () => void,
  usePreauthorization = false
) => {
  const { error } = result

  const intent = usePreauthorization ? result['paymentIntent'] : result['setupIntent']
  // TODO: remove brackets and add types

  if (intent) {
    handleSuccess()
  } else if (error.message) {
    notify('error', error.message)
    handleError()
  }
}

export const handleStripeCardRecuringPayment = (
  clientSecret: string,
  paymentMethod: string,
  stripePubKey: string,
  handleSuccess: () => void,
  handleError: () => void
): void => {
  const handleTransaction = (): void => {
    const stripeClient = createStripeClient(stripePubKey)
    handleStripeCardTransaction(stripeClient, { id: paymentMethod }, clientSecret, {}, true).then(
      (result: PaymentIntentResult | SetupIntentResult) =>
        handleTransactionResponse(result, handleSuccess, handleError, true)
    )
  }

  if (!isStripeInjected()) {
    injectStripeScript(handleTransaction)
  } else {
    handleTransaction()
  }
}

export const handleStripeSofortRecuringPayment = (
  clientSecret: string,
  stripePubKey: string,
  redirectLink: string,
  payerInfo: { countryCode: string; billingDetails: object } | undefined,
  handleSuccess: () => void,
  handleError: () => void
): void => {
  const handleTransaction = (): void => {
    const stripeClient = createStripeClient(stripePubKey)
    handleSofortTransaction(stripeClient, redirectLink, clientSecret, payerInfo).then((result: PaymentIntentResult) =>
      handleTransactionResponse(result, handleSuccess, handleError)
    )
  }

  if (!isStripeInjected()) {
    injectStripeScript(handleTransaction)
  } else {
    handleTransaction()
  }
}

export const handleStripeDigitalTransaction = (
  stripeClient: Stripe,
  expressCheckoutElement: StripeExpressCheckoutElement,
  elements: StripeElements,
  clientSecret: string,
  redirectLink: string,
  handleClose: () => void
) => {
  if (stripeClient && clientSecret) {
    expressCheckoutElement.on('confirm', async () => {
      const { error } = await stripeClient.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: redirectLink,
        },
      })

      if (error) {
        notify('error', error.message)

        if (handleClose) {
          handleClose()
        }
      }
    })
  }
}

export const convertPriceForStripe = (price: number): number => Math.round((Number(price) || 0) * 100)

export const allowedToUseStripe = () => {
  const webProtocol = getElopageConfig('webProtocol')
  const isHttp = webProtocol === 'http'
  return !(isProduction() && isHttp)
}
